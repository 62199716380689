

google.maps.Map.prototype.panToWithOffset = function(latlng, offsetX, offsetY) {
	var map = this;
	var ov = new google.maps.OverlayView();
	ov.onAdd = function() {
		var proj = this.getProjection();
		var aPoint = proj.fromLatLngToContainerPixel(latlng);
		aPoint.x = aPoint.x + offsetX;
		aPoint.y = aPoint.y + offsetY;
		map.panTo(proj.fromContainerPixelToLatLng(aPoint));
	};
	ov.draw = function() {};
	ov.setMap(this);
};

(function(factory) {
	if (typeof define === "function" && define.amd) {
		// AMD. Register as an anonymous module.
		define(["jquery"], factory);
	} else if (typeof module === "object" && module.exports) {
		// Node/CommonJS
		module.exports = factory(require("jquery"));
	} else {
		// Browser globals
		factory(jQuery);
	}
})(function($) {
	$.fn.googleMap = function() {
		this.each(function() {
			var $this = $(this);
			var googleMapContainer = $this.find(".js-Map__GoogleMaps");
			var markers = $this.find(".js-Map__Marker");
			var styles = [
				{
					featureType: "administrative.country",
					elementType: "geometry.stroke",
					stylers: [
						{
							color: "#ff6400"
						}
					]
				},
				{
					featureType: "administrative.land_parcel",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "administrative.neighborhood",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "administrative.province",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "landscape",
					elementType: "geometry.fill",
					stylers: [
						{
							color: "#fff4ed"
						}
					]
				},
				{
					featureType: "poi",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "poi",
					elementType: "labels.text",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "poi.business",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "road",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "road",
					elementType: "labels",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "road",
					elementType: "labels.icon",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "transit",
					stylers: [
						{
							visibility: "off"
						}
					]
				},
				{
					featureType: "water",
					elementType: "labels.text",
					stylers: [
						{
							visibility: "off"
						}
					]
				}
			];

			var map = new google.maps.Map(googleMapContainer.get(0), {
				zoom: parseInt(googleMapContainer.data("zoom")) || 6,
				center: { lat: parseFloat(googleMapContainer.data("lat")) || 51.945219003637234, lng: parseFloat(googleMapContainer.data("lng")) || 19.332256375000018 },
				styles: styles,
				mapTypeControl: false
			});

			var el = $this;
			function enableScrollingWithMouseWheel() {
				map.setOptions({ scrollwheel: true });
			}

			function disableScrollingWithMouseWheel() {
				map.setOptions({ scrollwheel: false });
			}

			google.maps.event.addListener(map, "mousedown", function() {
				enableScrollingWithMouseWheel();
			});

			$("body").on("mousedown", function(event) {
				var clickedInsideMap = $(event.target).parents($this).length > 0;

				if (!clickedInsideMap) {
					disableScrollingWithMouseWheel();
				}
			});

			$(window).scroll(function() {
				disableScrollingWithMouseWheel();
			});

			var contentString = "<div>default</div>";

			var infowindow = new google.maps.InfoWindow({
				content: contentString
			});

			var mapMarkers = [];
			markers.each(function(index, m) {
				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat($(m).data("lat")),
						lng: parseFloat($(m).data("lng"))
					},
					title: $(m).data("title"),
					icon: "/img/marker1.svg"
				});
				mapMarkers.push(marker);
			});

			mapMarkers.forEach(function(mapMarker, index) {
				mapMarker.addListener("click", function() {
					mapMarkers.forEach(function(otherMarker) {
						otherMarker.setIcon("/img/marker1.svg");
						if (otherMarker.infoBox) {
							otherMarker.infoBox.close();
							otherMarker.infoBox = null;
						}
					});
					var boxText = document.createElement("div");
					boxText.setAttribute("class", "Map__InfoBox");
					boxText.innerHTML = $(markers[index]).html();
					var btn = $('<button class="Map__CloseBtn"></button>');
					$(boxText).prepend(btn);
					mapMarker.setIcon("/img/marker2.svg");

					var ib = new InfoBox({
						content: boxText,
						alignBottom: true
					});
					mapMarker.infoBox = ib;
					btn.click(function() {
						ib.close();
						mapMarker.setIcon("/img/marker1.svg");
						mapMarker.infoBox = null;
					});
					ib.open(map, mapMarker);
					map.panToWithOffset(
						ib.getPosition(),
						0,
						window.outerWidth > window.screenSm ? -100 : -120
					);
				});
			});

			var markerStyles = [
				{
					url: "/img/cluster1.svg",
					height: 53,
					width: 40,
					anchor: [-10, 0],
					textColor: "#FFFFFF",
					textSize: 15
				}
			];
			var markerCluster = new MarkerClusterer(map, mapMarkers, {
				imagePath: "/img/cluster",
				styles: markerStyles
			});
		});
	};
});
