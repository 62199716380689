// Sticky Plugin v1.0.4 for jQuery
// =============
// Author: Anthony Garand
// Improvements by German M. Bravo (Kronuz) and Ruud Kamphuis (ruudk)
// Improvements by Leonardo C. Daronco (daronco)
// Created: 02/14/2011
// Date: 07/20/2015
// Website: http://stickyjs.com/
// Description: Makes an element on the page stick on the screen as you scroll
//              It will only set the 'top' and 'position' of your element, you
//              might need to adjust the width in some cases.

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {

	$.fn.loadMore = function() {
		this.each(function () {
			var $this = $(this);
			var button = $($this.find('.js-LoadMore__Btn'));
			var container = $($this.find('.js-LoadMore__Container'));
			var fallbackText = $($this.find('.js-LoadMore__FallbackText'));
			var entryType = $this.data('entry-type');
			var amount = $this.data('amount') ? $this.data('amount') : 10;
			var buttonLoadingText = $this.data('loading-text') ? $this.data('loading-text') : 'wird geladen';
			var buttonText = button.html();
			button.click(function(e) {
				button.disabled = true;
				button.addClass('disabled');
				button.html(buttonLoadingText);
				var offset = container.children().size();
				var jqxhr = $.post("/",
					{
						action: 'businessLogic/loadMoreListItems',
						offset: offset,
						entryType: entryType,
						amount: amount
					},
					function(data) {
						container.append(data.content);
						if (!data.moreToLoad) {
							button.remove();
							fallbackText.show();
						}
						picturefill();
					})
				.fail(function() {
					// console.log( "error" );
				})
				.always(function() {
					button.disabled = false;
					button.removeClass('disabled');
					button.html(buttonText);
				});
				e.preventDefault();
			})
		});
	};
}));
