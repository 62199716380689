"use strict";

var conf = require("./conf");
var $ = require("jquery");
window.jQuery = $;

window.screenSm = 544;
window.screenMd = 960;
window.screenLg = 1200;

var cookieConsent = require("./cookieConsent/index.js");

require("./libs/smoothscroll.js").polyfill();

require("./libs/perfect-scrollbar.js");
require("./libs/owl.carousel.js");

require("./jQueryPlugins/mainNav.js");
require("./jQueryPlugins/accordion.js");
require("./jQueryPlugins/loadMore.js");
require("./jQueryPlugins/rateForm.js");
require("./jQueryPlugins/modal.js");
require("./jQueryPlugins/map.js");
require("./jQueryPlugins/jquery.sticky-kit.js");
require("./jQueryPlugins/chart.js");

//require("./react/build/static/js/main.js");

require("./markerClusterer.js");
require("./infobox.js");

//-----------------------------------------

function initNav() {
  $(".MainNav").mainNav();
}

//-----------------------------------------

function init() {
  console.log("Initializing with", conf);
  console.log("Having jQuery version", $().jquery);

  new cookieConsent();

  initNav();

  // STICKY SIDEBAR
  $(".js-Sticky").stick_in_parent({ offset_top: 100 });

  // LANGUAGE SWITCH
  $(".LanguageSwitch").on("change", function() {
    var url = $(this).val(); // get selected value
    if (url) {
      // require a URL
      window.location = url; // redirect
    }
    return false;
  });

  // TEASER OWL CAROUSEL
  $(".owl-carousel").owlCarousel({
    loop: false,
    //margin:10,
    responsiveClass: true,
    stagePadding: 50,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      544: {
        items: 2
      }
    }
  });

  // LOAD MORE FUNCTIONALITY
  $(".js-LoadMore").loadMore();

  // MODALS
  $(".js-Modal").modal();

	// MAP
	window.addEventListener('app.cookieConsent.onChange', function(event) {
		if (app.cookies.cookieConsent.types.external) {
			$(".js-Map").googleMap();
		}
	});

	if (app.cookies.cookieConsent.types.external) {
		$(".js-Map").googleMap();
	}

  // INIT ACCORDIONS
  $(".js-Accordion").accordion();

  // INIT RATEFORM
  $(".js-RateForm").rateForm();

  // INIT TABLE
  $(".Table__Table").perfectScrollbar();

  // INIT CHARTS
  $(".Chart").chart();

	// INIT GA-OPT-OUT LINK
	$(".js-GoogleAnalytics__OptOut").on("click", function (e) {
		e.preventDefault();
		e.stopPropagation();

		// function defined in gtm
		if (typeof window.gaOptout === "function") {
			window.gaOptout();
		}

		return false;
	});
}

var App = {
  init: init
};

$(function() {
  App.init();
});
